import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import { UiCard } from '@uireact/card';
import { UiViewport, Breakpoints } from '@uireact/foundation';
import { UiText, UiLink } from '@uireact/text';
import Playground from '../../../src/Playground';
import { UiNavbar, UiNavbarItem } from '../src/';
import * as packageJson from '../package.json';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "uinavbar--uinavbaritem"
    }}>{`UiNavbar & UiNavbarItem`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/grid/navbar/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Render navbars for menus`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/navbar`}</p>
    </blockquote>
    <h2 {...{
      "id": "usage-as-a-stacked-navbar"
    }}>{`Usage as a stacked navbar`}</h2>
    <Playground __position={1} __code={'<UiNavbar orientation=\"stacked\">\n  <UiNavbarItem>\n    <UiLink href=\"https://uireact.io\" fullWidth>\n      Option 1\n    </UiLink>\n  </UiNavbarItem>\n  <UiNavbarItem>\n    <UiLink href=\"https://uireact.io\" fullWidth>\n      Option 2\n    </UiLink>\n  </UiNavbarItem>\n  <UiNavbarItem active>\n    <UiLink href=\"https://uireact.io\" fullWidth>\n      Option 3\n    </UiLink>\n  </UiNavbarItem>\n  <UiNavbarItem>\n    <UiLink href=\"https://uireact.io\" fullWidth>\n      Option 4\n    </UiLink>\n  </UiNavbarItem>\n</UiNavbar>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiViewport,
      Breakpoints,
      UiText,
      UiLink,
      Playground,
      UiNavbar,
      UiNavbarItem,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiNavbar orientation="stacked" mdxType="UiNavbar">
    <UiNavbarItem mdxType="UiNavbarItem">
      <UiLink href="https://uireact.io" fullWidth mdxType="UiLink">
        Option 1
      </UiLink>
    </UiNavbarItem>
    <UiNavbarItem mdxType="UiNavbarItem">
      <UiLink href="https://uireact.io" fullWidth mdxType="UiLink">
        Option 2
      </UiLink>
    </UiNavbarItem>
    <UiNavbarItem active mdxType="UiNavbarItem">
      <UiLink href="https://uireact.io" fullWidth mdxType="UiLink">
        Option 3
      </UiLink>
    </UiNavbarItem>
    <UiNavbarItem mdxType="UiNavbarItem">
      <UiLink href="https://uireact.io" fullWidth mdxType="UiLink">
        Option 4
      </UiLink>
    </UiNavbarItem>
  </UiNavbar>
    </Playground>
    <h2 {...{
      "id": "usage-inside-a-card"
    }}>{`Usage inside a card`}</h2>
    <Playground __position={2} __code={'<UiCard noPadding>\n  <UiNavbar orientation=\"stacked\" roundedCorners>\n    <UiNavbarItem>\n      <UiLink href=\"https://uireact.io\" fullWidth>\n        Option 1\n      </UiLink>\n    </UiNavbarItem>\n    <UiNavbarItem>\n      <UiLink href=\"https://uireact.io\" fullWidth>\n        Option 2\n      </UiLink>\n    </UiNavbarItem>\n    <UiNavbarItem active>\n      <UiLink href=\"https://uireact.io\" fullWidth>\n        Option 3\n      </UiLink>\n    </UiNavbarItem>\n  </UiNavbar>\n</UiCard>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiViewport,
      Breakpoints,
      UiText,
      UiLink,
      Playground,
      UiNavbar,
      UiNavbarItem,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiCard noPadding mdxType="UiCard">
    <UiNavbar orientation="stacked" roundedCorners mdxType="UiNavbar">
      <UiNavbarItem mdxType="UiNavbarItem">
        <UiLink href="https://uireact.io" fullWidth mdxType="UiLink">
          Option 1
        </UiLink>
      </UiNavbarItem>
      <UiNavbarItem mdxType="UiNavbarItem">
        <UiLink href="https://uireact.io" fullWidth mdxType="UiLink">
          Option 2
        </UiLink>
      </UiNavbarItem>
      <UiNavbarItem active mdxType="UiNavbarItem">
        <UiLink href="https://uireact.io" fullWidth mdxType="UiLink">
          Option 3
        </UiLink>
      </UiNavbarItem>
    </UiNavbar>
  </UiCard>
    </Playground>
    <h2 {...{
      "id": "usage-as-an-inline-navbar"
    }}>{`Usage as an inline navbar`}</h2>
    <Playground __position={3} __code={'<UiNavbar orientation=\"inline\" align=\"start\" stretchItems>\n  <UiNavbarItem>\n    <UiText>Option 1</UiText>\n  </UiNavbarItem>\n  <UiNavbarItem>\n    <UiText>Option 2</UiText>\n  </UiNavbarItem>\n</UiNavbar>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiViewport,
      Breakpoints,
      UiText,
      UiLink,
      Playground,
      UiNavbar,
      UiNavbarItem,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiNavbar orientation="inline" align="start" stretchItems mdxType="UiNavbar">
    <UiNavbarItem mdxType="UiNavbarItem">
      <UiText mdxType="UiText">Option 1</UiText>
    </UiNavbarItem>
    <UiNavbarItem mdxType="UiNavbarItem">
      <UiText mdxType="UiText">Option 2</UiText>
    </UiNavbarItem>
  </UiNavbar>
    </Playground>
    <h2 {...{
      "id": "with-bordered-styling-and-gap"
    }}>{`With bordered styling and gap`}</h2>
    <Playground __position={4} __code={'<UiNavbar\n  orientation=\"stacked\"\n  align=\"start\"\n  styling=\"bordered\"\n  gap=\"five\"\n  category=\"tertiary\"\n>\n  <UiNavbarItem active>\n    <UiText>Option 1</UiText>\n  </UiNavbarItem>\n  <UiNavbarItem>\n    <UiText>Option 2</UiText>\n  </UiNavbarItem>\n</UiNavbar>'} __scope={{
      props,
      DefaultLayout,
      Props,
      UiCard,
      UiViewport,
      Breakpoints,
      UiText,
      UiLink,
      Playground,
      UiNavbar,
      UiNavbarItem,
      packageJson,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiNavbar orientation="stacked" align="start" styling="bordered" gap="five" category="tertiary" mdxType="UiNavbar">
    <UiNavbarItem active mdxType="UiNavbarItem">
      <UiText mdxType="UiText">Option 1</UiText>
    </UiNavbarItem>
    <UiNavbarItem mdxType="UiNavbarItem">
      <UiText mdxType="UiText">Option 2</UiText>
    </UiNavbarItem>
  </UiNavbar>
    </Playground>
    <h2 {...{
      "id": "uinavbar-props"
    }}>{`UiNavbar Props`}</h2>
    <Props of={UiNavbar} mdxType="Props" />
    <h2 {...{
      "id": "uinavbaritem-props"
    }}>{`UiNavbarItem Props`}</h2>
    <Props of={UiNavbarItem} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      